import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import { PropTypes } from 'prop-types';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ListEngagement from '../../../components/ContentType/ListEngagement/ListEngagement';
import TextVisuelCTATwoCol from '../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import PageBanner from '../../../components/PageBanner/PageBanner';
import Seo from '../../../components/Seo/Seo';
import useMedias from '../../../hooks/useMedias';
import Layout from '../../../Layout';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import { getBreadCrumb, removeHtmlTag, getOffset } from '../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const Commitment = ({ data }) => {
  const intl = useIntl();
  const { getImage } = useMedias();

  const imagesArray = data.allImages.edges;
  const rse = data.rse;
  const rseBlock = rse?.relationships?.field_blocs?.find(
    (x) => x.__typename === 'block_content__block_engagement'
  );
  const rseTitle = rseBlock.field_offre_text;
  const rseList = rseBlock.field_bloc_text_1.map((c) => c.processed);
  const commitments = rse?.relationships?.field_blocs?.filter(
    (x) => x.__typename === 'block_content__basic'
  );
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, rse.path?.alias);
  const metaTags =  rse.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
  metaTags.forEach((meta) => {
    if(meta.attributes.name === 'title'){
        metaTitle = meta.attributes.content;
    }
    if(meta.attributes.name === 'description'){
        metaDesc = meta.attributes.content;
    }
  });
  const [allText, setAllText] = useState('');
  const [slideAnchor, setSlideAnchor] = useState('');

  let globalText = '';
  let allAnchor = [];
  useEffect(() => {
    rseBlock.field_bloc_text_1.forEach(
      (c) => (globalText += `${c.processed}.`)
    );
    commitments.forEach(
      (block) => {
        globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed}.${block.body?.processed}`;
        allAnchor.push(block?.field_anchor?.drupal_internal__target_id);
      }
    );

    setAllText(
      removeHtmlTag(
        `${rse.title}.${rse.field_sous_titre !== null && rse.field_sous_titre !== undefined
          ? rse.field_sous_titre
          : ''
        }.${globalText}`
      )
    );

    setSlideAnchor(allAnchor);

    if (typeof window !== 'undefined' && typeof location !== 'undefined') {
      let paramUrl = parseInt(new URLSearchParams(location?.search).get('anchor')) || '';
      setTimeout(function () {
        scrollToFunction(null, allAnchor.findIndex(x => x === paramUrl));
      }, 500);
    }
  }, []);

  const scrollToFunction = (e, directScroll = null) => {
    directScroll == null ? e.preventDefault() : null;

    const target = directScroll === null ? e.currentTarget.href.split('#')[1] : directScroll;
    const target_section = document.querySelectorAll(
      '.left_right_sections .section_list_commitments'
    )[parseInt(target, 10)];

    if (target_section) {
      window.scrollTo({
        top: getOffset(target_section).top - 100,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout>
      <Seo
        title={rse?.field_metatag?.title ? rse?.field_metatag?.title : metaTitle}
        description={rse?.field_metatag?.description ? rse?.field_metatag?.description : metaDesc}
      />

      <div className={classNames('page_template', 'page_engagements')}>
        <PageBanner
          visuel={getImage(
            imagesArray,
            rse?.relationships?.field_image?.drupal_internal__mid
          )}
          scroll={true}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                parentPage: {
                  title: breadCrumb?.parent?.name,
                  url: breadCrumb?.parent?.link,
                },
                currentPage: {
                  title: breadCrumb?.current?.name,
                  url: breadCrumb?.current?.link,
                },
                locale: rse.langcode,
              }}
            />

            <div
              className={classNames({
                title_audio:
                  allText && rse.field_version_audio && allText.length > 30,
              })}
            >
              <TitlePage
                color="color_white"
                title={rse.title}
                description={rse.field_sous_titre}
              />
              {allText && rse.field_version_audio && allText.length > 30 && (
                <AudioComponent
                  text={intl.formatMessage({
                    id: 'detailsActivities.button.listen.label',
                  })}
                  textToRead={allText}
                  color="color_white"
                />
              )}
            </div>
          </div>
        </PageBanner>
        <ListEngagement
          title={rseTitle}
          commitments={rseList}
          customClass="block_0_section"
          onClick={scrollToFunction}
          slideAnchor={slideAnchor}
        />
        <div className="left_right_sections">
          <div className="wrapper_page">
            {rse?.relationships?.field_blocs?.map((block, i) => {
              switch (block.__typename) {
                case 'block_content__basic':
                  return (
                    <section
                      key={i}
                      className={classNames(
                        'section_content',
                        'section_list_commitments',
                        'block_' + (i + 1) + '_section'
                      )}
                    >
                      <TextVisuelCTATwoCol
                        extremeLeft={
                          block?.field_no_padding &&
                            block?.field_alignment === 'left'
                            ? true
                            : false
                        }
                        extremeRight={
                          block?.field_no_padding &&
                            block?.field_alignment === 'left'
                            ? false
                            : true
                        }
                        orderInverse={
                          block?.field_alignment === 'right' ? true : false
                        }
                        visuel={getImage(
                          imagesArray,
                          block.relationships?.field_image?.drupal_internal__mid
                        )}
                        alignTop={true}
                        title={{
                          titleSize: 'h2',
                          titleText: block.field_title?.processed,
                          subtitle: block.field_subtitle?.processed,
                        }}
                        text={block.body.processed}
                        cta={{
                          ctaText: block.field_link?.title,
                          ctaLink: block.field_link?.url,
                        }}
                      />
                    </section>
                  );

                case 'block_content__block_citation':
                  globalText += block?.field_citation + '.';
                  return (
                    <section
                      className={classNames(
                        'section_content',
                        'block_' + (i + 1) + '_section'
                      )}
                      key={i}
                    >
                      <div className="quotemark_container">
                        <QuoteMark
                          lang={block.langcode}
                          size="m"
                          textWeight="m"
                          text={block?.field_citation}
                        />
                      </div>
                    </section>
                  );
              }
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
    rse: nodePage(langcode: { eq: $locale }, path: { alias: { eq: $slug } }) {
      title
      field_sous_titre
      field_version_audio
      langcode
      path {
        alias
        langcode
      }
      field_metatag {
        description
        title
      }
      metatag {
        attributes {
          content
          name
        }
      }
      relationships {
        field_image {
          drupal_internal__mid
        }
        field_blocs {
          __typename
          ... on Node {
            ... on block_content__basic {
              relationships {
                field_image {
                  drupal_internal__mid
                }
              }
              field_anchor {
                drupal_internal__target_id
              }
              field_title {
                processed
              }
              field_title_type
              field_subtitle {
                processed
              }
              body {
                processed
              }
              field_alignment
              field_no_padding
              field_link {
                title
                url
              }
            }
            ... on block_content__block_engagement {
              field_offre_text
              field_bloc_text_1 {
                processed
              }
            }
            ... on block_content__block_citation {
              field_citation
              langcode
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

Commitment.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Commitment;
