import * as React from 'react';

import PropTypes from 'prop-types';

import useMedias from '../../../hooks/useMedias';
import { Button } from '../../../Molecules/Button/Button';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

import './TextVisuelCTATwoCol.scss';

let classNames = require('classnames');

const TextVisuelCTATwoCol = ({
  extremeLeft,
  extremeRight,
  visuel,
  alt,
  title,
  text,
  orderInverse,
  alignTop,
  cta,
}) => {
  const { processUrl } = useMedias();

  const { titleText, subtitle } = title || {};
  const { ctaText, ctaLink } = cta || {};

  return (
    <div className="text_visuel_cta_two_col_component">
      <div
        className={classNames({
          cross_wrapper_extreme_right: extremeRight,
          cross_wrapper_extreme_left: extremeLeft,
        })}
      >
        <div
          className={classNames(
            'text_visuel',
            { order_inverse: orderInverse },
            { align_top: alignTop }
          )}
        >
          <div className="title_top only_mobile_tablet">
            {title && <TitleSection title={titleText} />}
          </div>
          <div className={classNames('visuel')}>
            <img
              src={processUrl(visuel.uri.url)}
              alt={alt}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          </div>
          <div className="text_container">
            <div className="text_center">
              {title && <TitleSection title={titleText} />}
              <div
                className="big_text"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              ></div>
              <div dangerouslySetInnerHTML={{ __html: text }}></div>
              {cta?.ctaLink && (
                <div className="btn_container">
                  <Button
                    label={ctaText}
                    link={ctaLink}
                    aria-label={ctaText + ' - ' + titleText}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TextVisuelCTATwoCol.defaultProps = {
  text: '',
  alt: '',
};

TextVisuelCTATwoCol.propTypes = {
  title: PropTypes.object,
  text: PropTypes.string,
  extremeRight: PropTypes.bool,
  extremeLeft: PropTypes.bool,
  cta: PropTypes.object,
  alt: PropTypes.string,
};

export default TextVisuelCTATwoCol;
