import React from 'react';

import {
  MaqsueBannerPage,
  MaqsueBannerPageMobile,
  MaqsueBannerPageTablet,
} from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';

import './PageBanner.scss';

let classNames = require('classnames');

const PageBanner = ({ visuel, scroll, backgroundColor, children }) => {
  const { processUrl } = useMedias();

  return (
    <div className="page_banner">
      <div className="visuel">
        {visuel && (
          <>
            <img
              className="only_desktop"
              src={processUrl(visuel.uri.url)}
              alt="talents"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                position: 'absolute',
              }}
            />
            <img
              className="only_mobile_tablet"
              src={processUrl(visuel.uri.url)}
              alt="talents"
              style={{ width: '100%', height: '500px', objectFit: 'cover' }}
            />
          </>
        )}
        <MaqsueBannerPage
          className={classNames(
            'shape_yellow_banner_bottom',
            'only_desktop',
            backgroundColor || ''
          )}
        />
        <MaqsueBannerPageMobile
          className={classNames(
            'shape_yellow_banner_bottom',
            'only_mobile',
            backgroundColor || ''
          )}
        />
        <MaqsueBannerPageTablet
          className={classNames(
            'shape_yellow_banner_bottom',
            'only_tablet',
            backgroundColor || ''
          )}
        />
      </div>
      <div className="visuel_overlay">
        {children}
        {scroll && (
          <div className="scroll_icon_container">
            <ScrollIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageBanner;
